import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Typography, Box, CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card, CardContent, Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, IconButton, Menu, MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { apiBaseUrl } from '../config';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import Pusher from 'pusher-js';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const StyledCard = styled(Card)(({ theme, isnew }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  transition: 'transform 0.3s ease-in-out, opacity 0.5s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
  },
  position: 'relative',
  opacity: isnew ? 0 : 1,
  animation: isnew ? 'fadeIn 0.5s ease-in-out forwards' : 'none',
  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 },
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

function Backtrack() {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(false);
  const [compareDialogOpen, setCompareDialogOpen] = useState(false);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [comparisonData, setComparisonData] = useState(null);
  const [detailedProperty, setDetailedProperty] = useState(null);
  const [detailedDialogOpen, setDetailedDialogOpen] = useState(false);
  const [monthsToShow, setMonthsToShow] = useState(3);
  const [anchorEl, setAnchorEl] = useState(null);
  const [archivedProperties, setArchivedProperties] = useState([]);
  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const [isRealtimeMode, setIsRealtimeMode] = useState(false);
  const [remainingTime, setRemainingTime] = useState(15 * 60);
  const [loadingDetails, setLoadingDetails] = useState({});
  const [newProperties, setNewProperties] = useState(new Set());
  const [hasStartedBacktrack, setHasStartedBacktrack] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const hasCalledBacktrackAll = useRef(false);

  const fetchArchivedProperties = useCallback(async () => {
    try {
      const response = await axios.get(apiBaseUrl + '/api/archived-properties');
      setArchivedProperties(response.data);
    } catch (error) {
      console.error('Error fetching archived properties:', error);
    }
  }, []);

  useEffect(() => {
    fetchArchivedProperties();
  }, [fetchArchivedProperties]);

  const handleArchiveProperty = async (property) => {
    try {
      await axios.post(apiBaseUrl + '/api/archive-property', {
        id: property.propertyId,
        name: property.propertyName
      });
      setArchivedProperties(prev => [...prev, { id: property.propertyId, name: property.propertyName }]);
      setProperties(prev => prev.filter(p => p.propertyId !== property.propertyId));
    } catch (error) {
      console.error('Error archiving property:', error);
    }
  };

  const handleUnarchiveProperty = async (propertyId) => {
    try {
      await axios.post(apiBaseUrl + '/api/unarchive-property', {
        propertyId: propertyId
      });
      const archivedProperty = archivedProperties.find(p => p.id === propertyId);
      if (archivedProperty) {
        setArchivedProperties(prev => prev.filter(p => p.id !== propertyId));
        setProperties(prev => [...prev, { propertyId: archivedProperty.id, propertyName: archivedProperty.name }]);
      }
    } catch (error) {
      console.error('Error unarchiving property:', error);
    }
  };

  const fetchProperties = useCallback(() => {
    setLoading(true);
    const pusher = new Pusher('f2da80e9773a761ef8ed', {
      cluster: 'us2',
      encrypted: true
    });
  
    const channel = pusher.subscribe('analytics_channel');
  
    channel.bind('property_data', (data) => {
      setProperties((prevProperties) => {
        const existingIndex = prevProperties.findIndex(p => p.propertyId === data.propertyId);
        if (existingIndex !== -1) {
          const updatedProperties = [...prevProperties];
          updatedProperties[existingIndex] = { ...updatedProperties[existingIndex], ...data };
          return updatedProperties
            .filter(p => !archivedProperties.some(ap => ap.id === p.propertyId))
            .sort((a, b) => b.currentMonthSessions - a.currentMonthSessions);
        } else {
          const newProperties = [...prevProperties, data];
          setNewProperties(prev => new Set(prev).add(data.propertyId));
          return newProperties
            .filter(p => !archivedProperties.some(ap => ap.id === p.propertyId))
            .sort((a, b) => b.currentMonthSessions - a.currentMonthSessions);
        }
      });
      setLoading(false);
    });
  
    channel.bind('pusher:error', (error) => {
      console.error('Pusher error:', error);
      setLoading(false);
    });
  
    // Trigger the backend to start sending data
    axios.get(`${apiBaseUrl}/api/backtrack-all`).catch(error => {
      console.error('Error triggering backtrack-all:', error);
      setLoading(false);
    });
  
    return () => {
      pusher.unsubscribe('analytics_channel');
      pusher.disconnect();
    };
  }, [archivedProperties]);

  const startBacktrack = useCallback(() => {
    setLoading(true);
    
    setHasStartedBacktrack(true);
    const pusher = new Pusher('f2da80e9773a761ef8ed', {
      cluster: 'us2',
      encrypted: true
    });
  
    const channel = pusher.subscribe('analytics_channel');
  
    channel.bind('property_data', (data) => {
      setProperties((prevProperties) => {
        const existingIndex = prevProperties.findIndex(p => p.propertyId === data.propertyId);
        if (existingIndex !== -1) {
          const updatedProperties = [...prevProperties];
          updatedProperties[existingIndex] = { ...updatedProperties[existingIndex], ...data };
          return updatedProperties
            .filter(p => !archivedProperties.some(ap => ap.id === p.propertyId))
            .sort((a, b) => b.currentMonthSessions - a.currentMonthSessions);
        } else {
          const newProperties = [...prevProperties, data];
          setNewProperties(prev => new Set(prev).add(data.propertyId));
          return newProperties
            .filter(p => !archivedProperties.some(ap => ap.id === p.propertyId))
            .sort((a, b) => b.currentMonthSessions - a.currentMonthSessions);
        }
      });
      setLoading(false);
    });
  
    channel.bind('pusher:error', (error) => {
      console.error('Pusher error:', error);
      setLoading(false);
    });
  
    // Only call backtrack-all if it hasn't been called before
    if (!hasCalledBacktrackAll.current) {
      axios.get(`${apiBaseUrl}/api/backtrack-all`).catch(error => {
        console.error('Error triggering backtrack-all:', error);
        setLoading(false);
      });
      hasCalledBacktrackAll.current = true;
    }
  
    return () => {
      pusher.unsubscribe('analytics_channel');
      pusher.disconnect();
    };
  }, [archivedProperties]);

  useEffect(() => {
    if (hasStartedBacktrack) {
      const cleanup = startBacktrack();
      return () => {
        if (cleanup) cleanup();
      };
    }
  }, [hasStartedBacktrack, startBacktrack]);

  useEffect(() => {
    if (newProperties.size > 0) {
      const timer = setTimeout(() => {
        setNewProperties(new Set());
      }, 2000); // Remove "new" status after 2 seconds
      return () => clearTimeout(timer);
    }
  }, [newProperties]);


  const handleCloseCompareDialog = () => {
    setCompareDialogOpen(false);
  };

  const handlePropertySelect = (property) => {
    setSelectedProperties(prev => {
      if (prev.includes(property)) {
        return prev.filter(p => p !== property);
      } else if (prev.length < 2) {
        return [...prev, property];
      }
      return prev;
    });
  };

  const handleCompare = async () => {
    if (selectedProperties.length !== 2) return;

    try {
      const response = await axios.get(`${apiBaseUrl}/api/compare-properties`, {
        params: {
          property1: selectedProperties[0].propertyId,
          property2: selectedProperties[1].propertyId
        }
      });

      setComparisonData(response.data);
      setCompareDialogOpen(false);
    } catch (error) {
      console.error('Error comparing properties:', error);
    }
  };

  const handleAddToCompare = (property) => {
    handlePropertySelect(property);
    if (selectedProperties.length === 1) {
      handleCompare();
    }
  };

  const handleOpenArchiveDialog = () => {
    setArchiveDialogOpen(true);
  };

  const handleCloseArchiveDialog = () => {
    setArchiveDialogOpen(false);
  };

  const handleCloseDetailedDialog = () => {
    setDetailedDialogOpen(false);
  };

  const handleMonthsMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMonthsMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMonthsChange = (months) => {
    setMonthsToShow(months);
    handleMonthsMenuClose();
  };

  const toggleRealtimeMode = () => {
    if (!isRealtimeMode && !hasStartedBacktrack) {
      setHasStartedBacktrack(true);
    }
    setIsRealtimeMode(!isRealtimeMode);
  };

  const calculateAverageSessionsLast3Months = (property) => {
    const last3Months = property.comparisons.slice(0, 3);
    return last3Months.reduce((sum, month) => sum + month.sessions, 0) / 3;
  };

  const categorizeProperties = () => {
    const categorized = {
      good: [],
      check: [],
      bad: []
    };
  
    properties.forEach(property => {
      const avgSessions = calculateAverageSessionsLast3Months(property);
      const currentSessions = property.projectedSessions;
  
      let category;
      let score;
  
      if (currentSessions === 0 && avgSessions === 0) {
        category = 'check';
        score = 0;
        console.log('Categorized as: check (both 0)');
      } else {
        const percentChange = ((currentSessions - avgSessions) / avgSessions) * 100;
        score = percentChange;
        console.log(`Percent Change: ${percentChange.toFixed(2)}%`);
        if (percentChange >= 0) {
          category = 'good';
          console.log('Categorized as: good');
        } else if (percentChange >= -20) {
          category = 'check';
          console.log('Categorized as: check');
        } else {
          category = 'bad';
          console.log('Categorized as: bad');
        }
      }
      
      categorized[category].push({ ...property, score });
    });
  
    return categorized;
  };

  useEffect(() => {
    if (isRealtimeMode) {
      const timer = setInterval(() => {
        setRemainingTime((prevTime) => {
          if (prevTime <= 1) {
            fetchProperties();
            return 15 * 60; // Reset to 15 minutes
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isRealtimeMode, fetchProperties]);


  
  useEffect(() => {
    let animationFrameId;

    const startScrolling = () => {
      const scrollDuration = 60000; // 60 seconds to complete a full cycle
      const startTime = Date.now();

      const updateScroll = () => {
        const currentTime = Date.now();
        const elapsedTime = (currentTime - startTime) % scrollDuration;
        const scrollHeight = document.documentElement.scrollHeight;
        const viewportHeight = window.innerHeight;
        const maxScroll = scrollHeight - viewportHeight;
        
        // Use a sine function for smoother scrolling
        const normalizedScroll = Math.sin((elapsedTime / scrollDuration) * Math.PI) * 0.5 + 0.5;
        const targetScroll = normalizedScroll * maxScroll;

        window.scrollTo({
          top: targetScroll,
          behavior: 'auto'
        });

        animationFrameId = requestAnimationFrame(updateScroll);
      };

      updateScroll();
    };

    const stopScrolling = () => {
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
        animationFrameId = null;
      }
    };

    const handleFullscreenChange = () => {
      if (document.fullscreenElement && isRealtimeMode) {
        startScrolling();
      } else {
        stopScrolling();
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);

    // Check fullscreen state and start scrolling if needed
    if (document.fullscreenElement && isRealtimeMode) {
      startScrolling();
    }

    return () => {
      stopScrolling();
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, [isRealtimeMode]);

  const fetchPropertyDetails = async (propertyId) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/api/property-details`, {
        params: { propertyId }
      });
      return { ...response.data, propertyId };
    } catch (error) {
      console.error('Error fetching property details:', error);
      throw error;
    }
  };

  const handleDetailedView = async (property) => {
    setLoadingDetails(prevState => ({ ...prevState, [property.propertyId]: true }));
    try {
      const details = await fetchPropertyDetails(property.propertyId);
      setDetailedProperty(details);
      setDetailedDialogOpen(true);
    } catch (error) {
      console.error("Error fetching property details:", error);
      // Handle error (e.g., show an error message to the user)
    } finally {
      setLoadingDetails(prevState => ({ ...prevState, [property.propertyId]: false }));
    }
  };

  const handleStartBacktrack = () => {
    setHasStartedBacktrack(true);
    setLoading(true);
    startBacktrack();
  };

  const handleRefresh = () => {
    startBacktrack();
    
  };

  const filteredProperties = properties.filter(property =>
    property.propertyName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
        Backtrack - All Properties
      </Typography>
      {!hasStartedBacktrack && (
        <Button
          variant="contained"
          onClick={handleStartBacktrack}
          startIcon={<PlayArrowIcon />}
          sx={{ mb: 2, mr: 2 }}
        >
          Start Backtrack
        </Button>
      )}
      {hasStartedBacktrack && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 4, flexWrap: 'wrap' }}>
          <IconButton 
            onClick={handleRefresh} 
            sx={{ mr: 2, mb: { xs: 1, sm: 0 }, color: 'primary.main' }}
          >
            <RefreshIcon />
          </IconButton>
          <Button 
            variant="contained" 
            onClick={toggleRealtimeMode} 
            sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          >
            {isRealtimeMode ? "Exit Realtime Mode" : "Enter Realtime Mode"}
          </Button>
          <Button variant="outlined" onClick={handleMonthsMenuClick} sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}>
            Show {monthsToShow} Months
          </Button>
          <Button 
            variant="outlined" 
            onClick={handleOpenArchiveDialog} 
            sx={{ mr: 2, mb: { xs: 1, sm: 0 } }}
          >
            View Archived Properties
          </Button>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: { xs: 1, sm: 0 }, maxWidth: '300px' }}>
            <SearchIcon sx={{ color: 'action.active', mr: 1 }} />
            <TextField
              label="Search properties"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
            />
          </Box>
        </Box>
      )}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMonthsMenuClose}
      >
        <MenuItem onClick={() => handleMonthsChange(3)}>3 Months</MenuItem>
        <MenuItem onClick={() => handleMonthsChange(6)}>6 Months</MenuItem>
        <MenuItem onClick={() => handleMonthsChange(12)}>12 Months</MenuItem>
      </Menu>
      {loading && hasStartedBacktrack ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      ) : hasStartedBacktrack ? (
        <>
          {isRealtimeMode ? (
            <>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Next refresh: {Math.floor(remainingTime / 60)}m {remainingTime % 60}s
              </Typography>
              <Grid container spacing={2} sx={{ flexWrap: 'nowrap', overflowX: 'auto' }}>
                {Object.entries(categorizeProperties()).map(([category, categoryProperties]) => (
                <Grid item xs={12} md={category === 'bad' ? 6 : 3} key={category}>
                  <Typography variant="subtitle1" sx={{
                    color: category === 'good' ? 'success.main' : category === 'check' ? 'warning.main' : 'error.main'
                  }}>
                    {category.charAt(0).toUpperCase() + category.slice(1)}
                  </Typography>
                  <Grid container spacing={1}>
                    {categoryProperties
                      .filter(property => property.currentMonthSessions > 0 && !property.propertyName.includes("Gal Agassi"))
                      .sort((a, b) => category === 'good' ? b.score - a.score : a.score - b.score)
                      .map(property => (
                      <Grid item xs={category === 'bad' ? 6 : 12} key={property.propertyId}>
                        <StyledCard sx={{ mb: 1, p: 1, height: '60px', width: '100%', overflow: 'hidden' }} isnew={newProperties.has(property.propertyId)}>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                            <Typography variant="subtitle2" sx={{ fontSize: '0.8rem', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              {property.propertyName.replace(" - GA4", "")}
                            </Typography>
                            {property.comparisons && property.comparisons[0] && (
                              <Box
                                component="span"
                                sx={{
                                  px: 0.5,
                                  borderRadius: 1,
                                  fontWeight: 'bold',
                                  fontSize: '0.7rem',
                                  color: 'white',
                                  bgcolor: category === 'good' ? 'success.main' : category === 'check' ? 'warning.main' : 'error.main',
                                }}
                              >
                                {property.score.toFixed(2)}%
                              </Box>
                            )}
                          </Box>
                          <Typography variant="caption" sx={{ fontSize: '0.7rem', display: 'block' }}>
                            Current: {property.currentMonthSessions.toLocaleString().padStart(8)} | Proj: {(property.projectedSessions ? property.projectedSessions.toLocaleString() : 'N/A').padStart(8)} | Exp: {(property.threeMonthAvgSessions ? Math.round(property.threeMonthAvgSessions).toLocaleString() : Math.round(calculateAverageSessionsLast3Months(property)).toLocaleString()).padStart(8)}
                          </Typography>
                        </StyledCard>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              ))}
              </Grid>
            </>
          ) : (
            <>
              {comparisonData && (
                <Card sx={{ mb: 3 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>Comparison</Typography>
                    <ResponsiveContainer width="100%" height={300}>
                      <LineChart data={comparisonData.chartData.map((item, index) => ({
                        ...item,
                        date: `${index + 1}`
                      }))}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis 
                          dataKey="date" 
                          interval={0}
                          tickFormatter={(value, index) => {
                            return value;
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="property1Sessions" stroke="#8884d8" name={selectedProperties[0].propertyName} />
                        <Line type="monotone" dataKey="property2Sessions" stroke="#82ca9d" name={selectedProperties[1].propertyName} />
                      </LineChart>
                    </ResponsiveContainer>
                  </CardContent>
                </Card>
              )}
              <Grid container spacing={2}>
                {filteredProperties.filter(property => !property.propertyName.includes("Gal Agassi")).map((property) => (
                <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={property.propertyId}>
                    <StyledCard sx={{ position: 'relative', overflow: 'visible', height: '100%' }} isnew={newProperties.has(property.propertyId)}>
                      <Box sx={{ position: 'absolute', top: -16, right: 8, display: 'flex', gap: 1 }}>
                        <IconButton
                          size="small"
                          onClick={() => handleAddToCompare(property)}
                          color={selectedProperties.includes(property) ? "primary" : "default"}
                          sx={{ bgcolor: 'background.paper', boxShadow: 1 }}
                        >
                          <AddIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleDetailedView(property)}
                          color="primary"
                          sx={{ bgcolor: 'background.paper', boxShadow: 1 }}
                        >
                          {loadingDetails[property.propertyId] ? (
                            <CircularProgress size={20} />
                          ) : (
                            <SearchIcon fontSize="small" />
                          )}
                        </IconButton>
                        <IconButton
                          size="small"
                          onClick={() => handleArchiveProperty(property)}
                          color="error"
                          sx={{ bgcolor: 'background.paper', boxShadow: 1 }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Box>
                      <CardContent sx={{ pt: 3, pb: 1, px: 2 }}>
                        <Typography variant="h6" gutterBottom noWrap fontWeight="bold" align="center">
                          {property.propertyName.replace(" - GA4", "").replace("https://", "").replace("http://","")}
                        </Typography>
                        <Grid container spacing={1} sx={{ mb: 1 }} justifyContent="center">
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" color="text.secondary" align="center">Current</Typography>
                            <Typography variant="body1" fontWeight="medium" align="center" sx={{ minHeight: '1.5em' }}>
                              {property.currentMonthSessions ? property.currentMonthSessions.toLocaleString() : 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" color="text.secondary" align="center">Projected</Typography>
                            <Typography variant="body1" fontWeight="medium" align="center" sx={{ minHeight: '1.5em' }}>
                              {property.projectedSessions ? property.projectedSessions.toLocaleString() : 'N/A'}
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="subtitle2" color="text.secondary" align="center">Est. Daily</Typography>
                            <Typography variant="body1" fontWeight="medium" align="center" sx={{ minHeight: '1.5em' }}>
                              {property.currentMonthSessions ? 
                                Math.round(property.currentMonthSessions / (new Date().getDate())).toLocaleString() : 'N/A'}
                            </Typography>
                          </Grid>
                        </Grid>
                        <TableContainer component={Paper} sx={{ mt: 1, boxShadow: 'none', bgcolor: 'background.default' }}>
                          <Table size="small">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell align="center">Period</StyledTableCell>
                                <StyledTableCell align="center">Sessions</StyledTableCell>
                                <StyledTableCell align="center">Change</StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(property.comparisons || []).slice(0, monthsToShow).map((comparison, index, sortedData) => {
                                const changeVsProjected = property.projectedSessions
                                  ? ((comparison.sessions - property.projectedSessions) / property.projectedSessions) * 100
                                  : 0;
                                return (
                                  <StyledTableRow key={index}>
                                    <TableCell align="center">
                                      <Typography variant="body2">{comparison.month.split(' ')[0]}</Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                      <Typography variant="body2">{comparison.sessions.toLocaleString()}</Typography>
                                    </TableCell>
                                    <TableCell align="center" sx={{
                                      color: changeVsProjected > 0 ? 'error.main' : 'success.main',
                                      display: 'flex',
                                      alignItems: 'center',
                                      justifyContent: 'center'
                                    }}>
                                      {changeVsProjected > 0 ? <TrendingDownIcon fontSize="small" /> : <TrendingUpIcon fontSize="small" />}
                                      <Typography variant="body2">
                                        {Math.abs(changeVsProjected).toFixed(2)}%
                                      </Typography>
                                    </TableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </CardContent>
                    </StyledCard>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </>
      ) : null}
      <Dialog open={compareDialogOpen} onClose={handleCloseCompareDialog}>
        <DialogTitle>Select Two Properties to Compare</DialogTitle>
        <DialogContent>
          <List>
            {properties.filter(property => !property.propertyName.includes("Gal Agassi")).map((property) => (
              <ListItem
                key={property.propertyId}
                button
                onClick={() => handlePropertySelect(property)}
                selected={selectedProperties.includes(property)}
              >
                <ListItemText primary={property.propertyName} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCompareDialog}>Cancel</Button>
          <Button onClick={handleCompare} disabled={selectedProperties.length !== 2}>Compare</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={detailedDialogOpen} onClose={handleCloseDetailedDialog} maxWidth="md" fullWidth>
        <DialogTitle>{detailedProperty?.propertyName} - Detailed View</DialogTitle>
        <DialogContent>
          {detailedProperty && (
            <>
              <Typography variant="h6" gutterBottom>Current Month Performance</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Typography variant="subtitle1">
                  Total Sessions: {detailedProperty.dailyData.reduce((sum, day) => sum + day.sessions, 0).toLocaleString()}
                </Typography>
              </Box>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart 
                  data={detailedProperty?.dailyData.sort((a, b) => new Date(a.date) - new Date(b.date)) || []} 
                  style={{ 
                    backgroundColor: '#2f2f2f', 
                    borderRadius: '8px',
                    padding: '10px'
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" stroke="#555" />
                  <XAxis 
                    dataKey="date" 
                    tickFormatter={(date) => {
                      const day = parseInt(date.slice(-2));
                      if (isNaN(day)) return '';
                      const suffix = ['th', 'st', 'nd', 'rd'];
                      const v = day % 100;
                      return day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
                    }}
                    stroke="#fff"
                  />
                  <YAxis stroke="#fff" />
                  <Tooltip 
                    labelFormatter={(date) => {
                      const year = date.slice(0, 4);
                      const month = date.slice(4, 6);
                      const day = date.slice(-2);
                      const formattedDate = new Date(year, parseInt(month) - 1, day);
                      return formattedDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                    }}
                    contentStyle={{ backgroundColor: '#444', border: 'none' }}
                    labelStyle={{ color: '#fff' }}
                  />
                  <Legend wrapperStyle={{ color: '#fff' }} />
                  <Line type="monotone" dataKey="sessions" stroke="#42a5f5" strokeWidth={2} name="Sessions" dot={{ fill: '#42a5f5' }} />
                 </LineChart>
              </ResponsiveContainer>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, mt: 4 }}>
                <Typography variant="h6" gutterBottom>Monthly Breakdown</Typography>
              </Box>
              <Grid container spacing={2}>
                {(() => {
                  const currentDate = new Date();
                  const currentMonth = currentDate.getMonth(); // 0-11
                  const sortedMonths = detailedProperty.monthlyData
                    .sort((a, b) => {
                      const aMonth = parseInt(a.month.slice(0, 2)) - 1; // 0-11
                      const bMonth = parseInt(b.month.slice(0, 2)) - 1; // 0-11
                      const aDistance = (currentMonth - aMonth + 12) % 12;
                      const bDistance = (currentMonth - bMonth + 12) % 12;
                      return aDistance - bDistance;
                    })
                    .filter((_, index) => index > 0); // Remove current month

                  return sortedMonths
                    .slice(0, monthsToShow)
                    .map((monthData, index, sortedData) => {
                      const nextMonthData = index > 0 ? sortedData[index - 1] : null;
                      const change = nextMonthData
                        ? ((monthData.sessions - nextMonthData.sessions) / nextMonthData.sessions) * 100
                        : 0;

                      const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
                      const monthIndex = parseInt(monthData.month.slice(0, 2)) - 1;
                      const monthLabel = `${monthNames[monthIndex]}`;

                      return (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <StyledCard>
                            <CardContent>
                              <Typography variant="h6" component="div">
                                {monthLabel}
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                Sessions: {monthData.sessions.toLocaleString()}
                              </Typography>
                              {!isNaN(change) && (
                                <Typography 
                                  variant="body2" 
                                  color={change >= 0 ? "success.main" : "error.main"}
                                  sx={{ display: 'flex', alignItems: 'center', mt: 1 }}
                                >
                                  {change >= 0 ? <TrendingUpIcon fontSize="small" /> : <TrendingDownIcon fontSize="small" />}
                                  {Math.abs(change).toFixed(2)}% {change >= 0 ? "increase" : "decrease"}
                                </Typography>
                              )}
                            </CardContent>
                          </StyledCard>
                        </Grid>
                      );
                    });
                })()}
              </Grid>
              <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Additional Metrics</Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>Bounce Rate</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].bounceRate !== undefined
                        ? (detailedProperty.dailyData[detailedProperty.dailyData.length - 1].bounceRate * 100).toFixed(2) + '%'
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>Avg. Session Duration</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].avgSessionDuration !== undefined
                        ? detailedProperty.dailyData[detailedProperty.dailyData.length - 1].avgSessionDuration.toFixed(2) + 's'
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>Page Views</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].pageViews !== undefined
                        ? detailedProperty.dailyData[detailedProperty.dailyData.length - 1].pageViews.toLocaleString()
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>New Users</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].newUsers !== undefined
                        ? detailedProperty.dailyData[detailedProperty.dailyData.length - 1].newUsers.toLocaleString()
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>Total Users</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].totalUsers !== undefined
                        ? detailedProperty.dailyData[detailedProperty.dailyData.length - 1].totalUsers.toLocaleString()
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Paper elevation={3} sx={{ p: 2, height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <Typography variant="subtitle1" gutterBottom>Conversions</Typography>
                    <Typography variant="h4">
                      {detailedProperty.dailyData.length > 0 && detailedProperty.dailyData[detailedProperty.dailyData.length - 1].conversions !== undefined
                        ? detailedProperty.dailyData[detailedProperty.dailyData.length - 1].conversions.toLocaleString()
                        : 'N/A'}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetailedDialog}>Close</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={archiveDialogOpen} onClose={handleCloseArchiveDialog}>
        <DialogTitle>Archived Properties</DialogTitle>
        <DialogContent>
          <List>
            {archivedProperties.map((property) => (
              <ListItem key={property.id}>
                <ListItemText primary={property.name} />
                <IconButton onClick={() => handleUnarchiveProperty(property.id)}>
                  <AddIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseArchiveDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}


export default Backtrack;